@tailwind base;
@tailwind components;
@tailwind utilities;


html, body{
    background-color: var(--body);
    min-width: 350px;
    scroll-behavior: smooth;
    font-family: 'Raleway', sans-serif;
}

h1,h2,h3,h4,h5,h6,span,p,section,div {
    font-family: 'Raleway', sans-serif;
}

.section {
    min-height: 500px;
}

.mainapp-banner {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.mainapp-banner-sized {
    min-height: 70vh;
}

.team-members-card {
    background-size: cover;
    border-radius: 50%;
}

.team-section {
    background-size: 500px auto;
}

.privacy-policy-main p {
    margin-bottom: 20px;
}

.privacy-policy-main h2 {
    color: dodgerblue;
    margin-top: 20px;
    font-weight: 800;
}

.privacy-policy-main li{
    margin-left: 30px;
}

.privacy-policy-main ul{
    list-style-type: disc;
}

.privacy-policy-main h2 {
    
}